@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  min-width: 300px;
}

.app {
  background-color: black;
  color: white;
}

.header {
  position: fixed;
  z-index: 2;
  width: 100vw;
  max-width: 100%;
  min-width: 300px;
  height: 10vh;
  min-height: 75px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgb(41, 2, 2);

  font-family: 'Libre Baskerville', serif;
  font-size: 1rem;
}

.header-menu {
  height: 10vh;
  min-height: 75px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.header-menu li {
  margin: 20px;
  cursor: pointer;
}

.header-menu li:hover {
  font-weight: 800;
  text-decoration: underline;
}

.menu-item {
  color: aliceblue;
  text-decoration: none;
}

.menu-item-active {
  color: #c8a063;
  font-weight: bold;
  text-decoration: underline;
}

.header-logo-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 10vh;
  min-height: 75px;
}

.header-logo {
  margin-left: 20px;
  height: 9vh;
  min-height: 70px;
}

.header-translate {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

.main-logo-wrapper {
  position: relative;
}

.main-logo-wrapper img {
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20vh;
  height: 40vh;
  min-height: 200px;
  max-height: 500px;
}

.carousel-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 300px;
  height: 100vh;
  min-height: 600px;
  max-width: 100%;
  will-change: opacity;
  object-fit: cover;
}

.section {
  height: 100vh;
  min-height: 600px;
}

.section-layout {
  display: grid;
  grid-template-columns: 50fr 50fr;
  height: 100vh;
}

.section-content-wrapper {
  height: 100vh;
  min-height: 600px;
  padding: 0px 15px 0px 15px;
  text-align: center;
  background-color: rgb(41, 2, 2);
  background-image: url(/static/media/logo_mark-768w.25b0d0ca.png);
  background-size: 25rem;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: screen;

  font-family: 'Libre Baskerville', serif;
  color: aliceblue;
  word-wrap: break-word;
  word-break: break-word;
}

.section-extra-wrapper {
  height: 100vh;
  min-height: 600px;
}

.menu-link {
  text-decoration: underline;
  color: white;
}

.icon-list {
  display: -webkit-flex;
  display: flex;
}

.icon-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
}

.gallery {
  padding: 10px;
  box-sizing: border-box;

  width: 100%;
  max-height: calc(100% - 10px);
  overflow-y: scroll;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.gallery-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

/*
* Ensures correct aspect ratio 2:3; height:width
* See https://stackoverflow.com/questions/51577807/responsive-css-grid-with-persistent-aspect-ratio
*/
.gallery-card:before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(2 / 3 * 100%);
}

.gallery-card-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5%;
}

.contact-detail {
  display: block;
  color: white;
  text-decoration: underline;
  font-style: normal;
}

.section-title {
  font-size: 3rem;
  text-align: center;
  color: #c8a063;
  font-weight: 900;
}

.section-subtitle {
  font-size: 1.5rem;
  color: white;
  font-weight: 900;
}

.section p {
  font-size: 1rem;
}

@media only screen and (max-width: 700px) {
  .section-title {
    font-size: 2.5rem;
    font-weight: 900;
  }

  .section-subtitle {
    font-size: 1.25rem;
    color: white;
    font-weight: 900;
  }

  .header {
    overflow-x: scroll;
  }

  .section {
    height: 200vh;
    min-height: 1200px;
  }

  .section-layout {
    grid-template-columns: 100fr;
  }

  .section-content-wrapper {
    background-size: 20rem;
  }

  #home {
    height: 100vh;
    min-height: 600px;
  }
}

